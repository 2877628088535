<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @submit="handleSubmit" :form="form">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="类型">
              <a-select placeholder="请选择" v-decorator="['queryType', { initialValue: queryParam.type }]">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="(val, key) in emailType" :key="key" :value="key">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="关键字">
              <a-input v-decorator="[
                'queryKeywords',
                {
                  initialValue: queryParam.keywords
                }
              ]" placeholder="ID/名称/主题" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button htmlType="submit" :loading="submitLoading" type="primary">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
              <a-button style="float: right" @click="goAddTemplate">添加邮件模板</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table :columns="columns" :dataSource="tableData" :loading="tableLoading" rowKey="id">

      <span slot="type" slot-scope="text">
        {{ emailType[text] }}
      </span>

      <span slot="status" slot-scope="text">
        {{ text | statusFilter }}
      </span>

      <span slot="action" slot-scope="text, record">
        <a @click="goEditTemplate(record)">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm v-if="tableData.length" title="确定删除该模板?" @confirm="() => onDelete(record.id)">
          <a href="javascript:;">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

  </a-card>
</template>

<script>
import { emailTemplateList, delEmailTemplate } from '@/api/email'
import merge from 'webpack-merge'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '类型',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' }
  },
  {
    title: '模板名称',
    dataIndex: 'name'
  },
  {
    title: '主题',
    dataIndex: 'subject'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }]

export default {
  name: 'EmailList',
  data() {
    return {
      description: '列表使用场景：后台管理中的权限管理以及角色管理，可用于基于 RBAC 设计的角色权限控制，颗粒度细到每一个操作类型。',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // form
      form: this.$form.createForm(this),
      // 查询参数
      queryParam: {
        keywords: this.$route.query.keywords,
        type: (this.$route.query.type ? this.$route.query.type : '')
      },
      columns,
      tableData: [],
      emailType: [],
      submitLoading: false,
      tableLoading: false
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: '正常',
        2: '禁用'
      }
      return statusMap[status]
    }
  },
  created() {
    const parameter = this.queryParam
    this.loadData(parameter)
  },
  methods: {
    loadData(parameter) {
      this.tableLoading = true
      new Promise((resolve, reject) => {
        emailTemplateList(parameter).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.tableData = res.data.list
        this.emailType = res.data.type
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message,
          duration: 4
        })
      }).finally(() => {
        this.tableLoading = false
      })
    },
    goAddTemplate() {
      this.$router.push({ 'name': 'EmailTemplateEdit' })
    },
    goEditTemplate(record) {
      this.$router.push({ 'name': 'EmailTemplateEdit', query: { id: record.id } })
    },
    onDelete(id) {
      new Promise((resolve, reject) => {
        delEmailTemplate({ 'id': id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        // 删除成功
        this.$notification.success({
          message: res.status,
          description: res.message
        })
        this.loadData()
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message
        })
      })
    },
    handleSubmit(e) {
      // 重置查询条件，并重新请求
      e.preventDefault()
      const parameter = {}
      parameter.keywords = this.form.getFieldValue('queryKeywords')
      parameter.type = this.form.getFieldValue('queryType')
      this.submitLoading = true
      this.loadData(parameter)
      this.submitLoading = false
      this.$router.push({
        query: merge(this.$route.query, parameter)
      })
    },
    resetQuery() {
      // 重置查询条件，并重新请求
      this.form.setFieldsValue({ 'queryType': '', queryKeywords: '' })
      this.$router.push({
        query: merge({}, {})
      })
      this.submitLoading = true
      this.loadData()
      this.submitLoading = false
    }
  }
}
</script>
